// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

// Header
$(".top-content").appendTo(".menuAdminContainer");
$(".header-search").prependTo(".top-content");
$(".menuMain").prependTo(".headerContent");

// $(".homeBox1").appendTo(".homeBox2");
// $(".homeBox3").prependTo(".homeFeedBox1");
// $(".homeBox1").prependTo(".homeBox6");
// $(".homeBox2").prependTo(".homeBox6");
// $(".homeBox3").prependTo(".homeBox6");

$(".homeFeedBox1").appendTo("#recounting");

// dont move homeboxes move feeds
$(document).ready(function () {

  // move menu to between logo and main CTA
  function handleMenu() {
    if ($(window).width() > 767) {
      // Check if the CTA section's parent is the header content or not
      if (!$("#pageHeader .top-header-wrapper").parent().is($("#pageHeader .headerContent .mainLogo + .mainCallToAction"))) {
        // Else append the menu to the header content
        $("#pageHeader .top-header-wrapper").prependTo("#pageHeader .headerContent .mainLogo + .mainCallToAction");
      }
    } else {
      // Check if the CTA section's parent is the menu main or not
      if (!$("#pageHeader .top-header-wrapper").parent().is($("#pageHeader #menuMain"))) {
        // Else insert the new element after the target element
        $("#pageHeader .top-header-wrapper").prependTo("#pageHeader #menuMain");
        var seen = {};
        $("#pageHeader .top-header-wrapper").each(function() {
          var txt = $(this).text();
          if (seen[txt]) {
            $(this).remove();
          }
          else {
            seen[txt] = true;
          }
        });
      }
    }
  }

  // Initial call to handleMenu
  handleMenu();
  
  // Attach the handleMenu function to the window resize event
  $(window).on('resize', handleMenu);

  //add button to each slide article 
  $(".homeFeed.homeFeedBox1 .feedItem, .homeFeed.homeFeedBox2 .feedItem").each(function () {
    const href = $(this).children("a").attr("href");
    const carouselDetail = $(this).find(".feedItemDetailsWrapper");

    $(carouselDetail).append(`<a href="${href}" class="button">Read More </a>`)
  });

  //add button to each slide article 
  $(".homeFeed.homeFeedBox3 .feedItem").each(function () {
    const href = $(this).children("a").attr("href");
    const carouselDetail = $(this).find(".feedItemDetailsWrapper");

    $(carouselDetail).append(`<a href="${href}" class="button">Read More </a>`)
  });

  $(".homeFeed.homeFeedBox1").each(function () {
    const carouselDetail = $(this);
    const href = "/Blogs/Stories"; // Replace with your desired href
  
    carouselDetail.append('<button class="button bottom"><a href="' + href + '">READ ALL STORIES</a></button>');
  });

  $(".homeFeed.homeFeedBox2").each(function () {
    const carouselDetail = $(this);
    const href = "/Pages/News"; // Replace with your desired href

    carouselDetail.append('<button class="button bottom"><a href="' + href + '">READ ALL NEWS</a></button>');
  });

  //Count Up Animation
  var countUpStats = true;
  var countUpStatsDuration = 3000;

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function startCountUpAnimation(target, duration, end) {
    const increment = Math.ceil(end / (duration / 16));
    let current = 0;
  
    const interval = setInterval(() => {
      current += increment;
    
      if (current >= end) {
        clearInterval(interval);
        current = end;
      }
    
      target.textContent = formatNumberWithCommas(current);
    }, 16);
  }
  
  function handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const duration = 2000; // Set the desired duration for the animation
    
        // Retrieve the end value from the data attribute
        const end = parseInt(target.dataset.endValue.replace(/,/g, ""));

        // Start count-up animation
        startCountUpAnimation(target, duration, end);
      }
    });
  }

  // Create an intersection observer
  const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

  // Select all elements with the "countup" class
  const targetElements = document.querySelectorAll('.countup');

  // Iterate over the target elements
  targetElements.forEach(targetElement => {
    // Store the end value in a data attribute
    targetElement.dataset.endValue = targetElement.textContent;
  
    // Observe each target element
    observer.observe(targetElement);
  });

  $(".listing .headerText .listingIntro").prependTo(".listing .contentBlockWrapper");

  var targetText = "incredible individuals"; // The text you want to target
  var paragraph = $('.desc'); // Select the paragraph using its class
  
  // Update the HTML content of the paragraph, replacing the target text with the same text wrapped in a span with a specific CSS class
  paragraph.html(function() {
    return $(this).html().replace(targetText, '<span class="pink-text">' + targetText + '</span>');
  });
});

//Listing page 
// $(".listing .headerText").appendTo("#startcontent");
$(".mainCallToAction .sign-language").appendTo("#menuAdmin");
$(".mainCallToAction .reciteme").appendTo("#menuAdmin");
  
// Exit btn
// Create the exit button element
const exitButton = document.createElement('a');
exitButton.classList.add('exit_btn');
exitButton.href = 'https://www.google.co.uk/search?q=weather'; // Set the link URL
exitButton.target = '_blank'; // Open the link in a new tab

// Set the button text (you can customize this)
exitButton.innerText = 'Exit';

// Append the button to the document body
document.body.appendChild(exitButton);